/* LHSBar */

:root {
  --logo-bg-color: rgb(27 37 70);
}

/* ---------logo---------- */
.font-link {
  font-family: "Playfair Display", serif;
}

.logo-bg {
  top: 0;
  width: 300px;
  height: 15%;
  background-color: RGB(255 255 255);
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua",
    Georgia, serif;
  font-size: 2.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgb(27 37 70);
}
.logo-text {
  margin-left: 10px;
}

/* --------LHSBar-body------- */

.LHSBar-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85%;
  width: 300px;
  background-color: var(--logo-bg-color);
  color: white;
  padding-top: 15%;
}
.fonts-size {
  display: inline-block;
  font-size: 2.2vh;
  margin: 10px 0;
  font-size: 135%;
}
.img-block {
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60%;
  overflow: auto;
}
.img-block::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
.img-block::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #ffffff71;
  border-radius: 5px;
}
.img-block::-webkit-scrollbar-track {
  /* Background */

  border-radius: 0px 0px 5px 0px;
}

.img-size {
  width: 250px;
  height: fit-content;
  padding: 5px;
  cursor: pointer;
}
.view-image-base {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.view-image-inside {
  position: absolute;
  top: 5%;
  left: 25%;
  width: 50%;
  height: 90%;
  background-color: var(--components-bg-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}
.view-image-inside::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
.view-image-inside::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #72717171;
  border-radius: 5px;
}
.view-image-inside::-webkit-scrollbar-track {
  /* Background */

  border-radius: 0px 0px 5px 0px;
}

.view-image-inside-image {
  width: fit-content;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 6px;
}

.preview-pdf {
  width: 100%;
  height: 85vh;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 0px;
}

.view-image-exit {
  position: fixed;
  top: 2%;
  right: 25%;
  width: 25px;
  height: 25px;
  background-color: rgba(255, 0, 0, 0.822);
  border-radius: 5px;
  text-align: center;
  font-size: large;
  color: white;
  cursor: pointer;
}
.view-image-exit-text {
  width: inherit;
  height: inherit;
  /* position: absolute; */
  /* top:-8%; */
}
.Download {
  background-color: rgb(18, 133, 166);
  border: 0px;
  border-radius: 5px;
  color: white;
  width: 50%;
  height: 5%;
  margin-top: 50px;
  cursor: pointer;
}
/* -------LHSBar-bottom------ */

.LHSBar-bottom-bg {
  height: 3%;
  width: 300px;
  background-color: var(--logo-bg-color);
  color: white;
}
.bottom_text {
  margin-left: 10px;
  font-size: 1.5vh;
}

.right-align {
  text-align: right;
}

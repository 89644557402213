/* Comment Component */

.comment-card-body {
  display: flex;
  column-gap: 12px;
}

.comment-field-label {
  display: flex;
  align-items: end;
  column-gap: 5px;
  color: #5e5e5e;
  margin: 0 0 2px;
  padding: 2px 0
}

.comment-subtitle-label {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.new-comment-card-body {
  display: flex;
  column-gap: 12px;
  padding-bottom: 32px;
  padding-top: 12px;
}

/* .comment-drawer-body {
  padding-bottom: 100px;
} */

.comment-profile-row {
  display: grid;
  flex-direction: column;
  grid-template-columns: 2fr 4fr;
  /* gap: 10px; */
  align-items: flex-start;
}

.comment-profile-title {
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-weight: 500;
  color: #5e5e5e;
}

.comment-profile-description {
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.comment-content {
  display: flex;
  flex-direction: column;
  color: #5e5e5e;
  /* font-weight: 600; */
}

.new-comment-subtitle-label {
  font-weight: 500;
}

.interview-date-container {
  justify-content: space-between !important;
  flex-grow: 1 !important;
}

.interview-date {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.comment-commenter {
  color: var(--themePrimary);
  font-weight: 600;
}


.new-comment-container {
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

.comment-container {
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 12px;
  padding: 12px 12px 44px;
}

.comment-container-right {
  display: grid !important;
  grid-template-columns: 3fr 150px;
  /* justify-content: space-between; */
  gap: 44px;
  width: 100%
}

.new-avatar-container {
  background-color: #fff;
  width: 32px;
}

.comment-date-rating-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.comment-form-content {
  padding: 6px 12px;
  /* color: #858585; */
}

.interview-date-content {
  padding: 0 12px;
  /* color: #858585; */
}

p {
  margin: 0;
}

.textarea {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  resize: vertical;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
}
/* Analysis View */

.candidate-analysis-header {
  padding: 0 15px;
  font-size: 16px;
}

/* CVRaderChart */

.RadarChart-legend-text {
  color: black;
  font-weight: 500;
}

.recharts-text.recharts-polar-angle-axis-tick-value {
  fill: black;
  font-weight: 500;
  font-size: 16px;
}

/* OneBoxContent */

/* ----------------one-box-rectangle----------------------- */

.one-box-rectangle {
  background-color: var(--dashboard-components-bg-color);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: var(--components-margin);
}

.one-box-rectangle-title {
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.one-box-rectangle-title-text {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.one-box-rectangle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60%;
}

.one-box-rectangle-bottom {
  height: 80%;
  width: 90%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  font-size: 130%;
}

.bg-color-purple {
  background-color: var(--purple);
  color: rgb(255, 255, 255);
}

.bg-color-orange {
  background-color: var(--orange);
  color: rgb(255, 255, 255);
  font-size: 120%;
}

.bg-color-green {
  background-color: var(--green);
  color: rgb(255, 255, 255);
  font-size: 120%;
}

.bg-color-red {
  background-color: var(--red);
  color: rgb(255, 255, 255);
  font-size: 120%;
}

.bg-color-jardines {
  background-color: var(--jardines-blue-color);
  color: rgb(255, 255, 255);
  font-size: 120%;
}

/* ----------------one-box-square----------------------- */

.one-box-square {
  background-color: var(--dashboard-components-bg-color);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: var(--components-margin);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.one-box-square-Pink {
  width: 100%;
  background-color: var(--jardines-blue-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 35%;
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-size: 135%;
}

.one-box-square-Purple {
  width: 100%;
  background-color: var(--jardines-blue-color);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 35%;
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-size: 135%;
}

.one-box-square-deeppurple {
  width: 100%;
  background-color: var(--jardines-blue-color);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 35%;
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-size: 135%;
}

.one-box-square-orange {
  width: 100%;
  background-color: var(--jardines-blue-color);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 35%;
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-size: 135%;
}

.text-color-orange {
  color: var(--orange);
  text-align: center;
}

.text-color-green {
  color: var(--green);
  text-align: center;
}

.text-color-red {
  color: var(--red);
  text-align: center;
}

.one-box-square-value {
  width: 90%;
  background-color: var(--dashboard-components-bg-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 65%;
  border-radius: 0px 0px 5px 5px;
  font-size: 120%;
  text-align: center;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.fullsize {
  width: 100%;
  height: 100%;
}

._90size {
  width: 90%;
  height: 90%;
}

.onecolumn {
  display: flex;
  flex-direction: column;
}

.column-center {
  align-items: center;
}

.row-center {
  justify-content: center;
}

.padding-topleft-corner {
  padding-top: 10px;
  padding-left: 15px;
}

.analysis-dashboard-top-bar {
  display: none;
}

.analysis-dashboard-page {
  display: flex;
  flex-direction: row;
  background-color: var(--dashboard-bg-color);
  height: 100vh;
  width: 100%;
}

.RadarChart-Base {
  background-color: var(--dashboard-components-bg-color);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: var(--components-margin);
  /* overflow: auto; */
}

.RadarChart-Base::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.RadarChart-Base::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #72717171;
  border-radius: 5px;
}

.RadarChart-Base::-webkit-scrollbar-track {
  /* Background */
  background: var(--dashboard-components-bg-color);
  border-radius: 0px 0px 5px 0px;
}

.RadarChart-Title {
  size: 5%;
  box-sizing: border-box;
}

.RadarChart-Title-Text {
  width: 100%;
  height: 100%;
  font-weight: bold;
}

.RadarChart-Chart {
  box-sizing: border-box;
  background-color: var(--dashboard-components-bg-color);
  height: 94%;
  align-items: center;
  display: flex;
}

.RHS-grid-container-out {
  background-color: var(--dashboard-bg-color);

  width: 100%;
}

.RHS-grid-container {
  display: grid;
  grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 5.5% 12.5%;
  grid-template-rows: 17.5% 10% 15.5% 10% 20% 21%;
  margin: 15px;
  height: calc(100vh - 20px);
  gap: 1%;
}

.RHS-grid-item1 {
  grid-area: 1 / 1 / 2 / 3;
}

.RHS-grid-item2 {
  grid-area: 1 / 3 / 2 / 5;
}

.RHS-grid-item3 {
  grid-area: 5 / 3 / 2 / 5;
}

.RHS-grid-item4 {
  grid-area: 2 / 1 / 4 / 3;
}

.RHS-grid-item5 {
  grid-area: 5 / 2 / 4 / 3;
}

.RHS-grid-item6 {
  grid-area: 5 / 1 / 4 / 2;
}

.RHS-grid-item7 {
  grid-area: 5 / 1 / 6 / 2;
}

.RHS-grid-item8 {
  grid-area: 5 / 2 / 6 / 3;
}

.RHS-grid-item9 {
  grid-area: 5 / 1 / 7 / 3;
}

.printable-skills-matched {
  display: none;
}

.RHS-grid-item10 {
  grid-area: 1 / 5 / 5 / 9;
}

.RHS-grid-item11 {
  grid-area: 5 / 3 / 7 / 9;
}

/* ----------------------PieChart---------------------------- */

.PieChart-Base {
  box-sizing: border-box;
  background-color: var(--dashboard-components-bg-color);
  border-radius: 5px;
}

.PieChart-title {
  box-sizing: border-box;
  width: 100%;
  height: 10%;
}

.PieChart-bottle {
  width: 100%;
  height: 0%;
  padding-left: 15px;
}

.PieChar-pie {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.PieChart-content-pie {
  width: 25vh;
  height: 25vh;
  border-radius: 50%;
  display: flex;
  position: relative;
}

.PieChart-content-pie-inside {
  position: absolute;
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background-color: var(--dashboard-components-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PieChart-content-pie-inside_text {
  font-size: calc((9vh) / 2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PieChart-bottle:hover {
  color: var(--dashboard-bg-color);
  cursor: pointer;
}

.view-box-base {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1;
}

.view-box-inside {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 600px;
  transform: translate(-50%, -50%);
  background-color: var(--dashboard-components-bg-color);
  border-radius: 5px;
}

.view-box-exit {
  position: absolute;
  top: -30px;
  right: 0%;
  width: 25px;
  height: 25px;
  background-color: rgba(255, 0, 0, 0.822);
  border-radius: 5px;
  text-align: center;
  font-size: 100%;
  color: white;
  cursor: pointer;
}

.view-box-exit-text {
  width: inherit;
  height: inherit;
  position: absolute;
  top: -8%;
}

.view-box-inside-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.view-box-inside-content-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
}

.view-box-inside-content-value {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
}

.view-box-inside-content-left-title {
  width: 49%;
  background-color: var(--purple);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  font-size: 100%;
  border-radius: 5px 0px 0px 5px;
}

.view-box-inside-content-middle-title {
  background-color: var(--purple);
  width: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.view-box-inside-content-right-title {
  background-color: var(--purple);
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  font-size: 100%;
  border-radius: 0px 5px 5px 0px;
}

.view-box-inside-content-left-value {
  width: 49%;
  height: 100%;
  border-radius: 5px 0px 0px 5px;

  overflow: auto;
}

.view-box-inside-content-middle-value {
  width: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.view-box-inside-content-right-value {
  width: 49%;
  height: 100%;
  overflow: auto;
}

.view-box-inside-content-left-value::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.view-box-inside-content-left-value::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #72717171;
  border-radius: 5px;
}

.view-box-inside-content-left-value::-webkit-scrollbar-track {
  /* Background */
  background: var(--dashboard-components-bg-color);
  border-radius: 0px 0px 5px 0px;
}

.view-box-inside-content-right-value::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.view-box-inside-content-right-value::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #72717171;
  border-radius: 5px;
}

.view-box-inside-content-right-value::-webkit-scrollbar-track {
  /* Background */
  background: var(--dashboard-components-bg-color);
  border-radius: 0px 0px 5px 0px;
}

.view-item {
  /* font-size: 100%;
  min-height: 60px;
  width: 900px;
  margin: 10px;
  padding: 10px 20px 0px 20px; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.align {
  overflow: auto;
}

.left {
  float: left;
}

.true {
  margin: 10px 30px 10px 0px;
  width: 40px;
  background-color: var(--green);
  height: 40px;
  border-radius: 20px;
  position: relative;
}

.true::before {
  background-color: white;
  width: 5px;
  height: 26px;
  top: 20%;
  left: 55%;
  border-radius: 2.5px;
  transform: rotate(35deg);
  content: "";
  position: absolute;
}

.true::after {
  background-color: white;
  width: 5px;
  height: 18px;
  top: 40%;
  left: 28%;
  border-radius: 2.5px;
  transform: rotate(-45deg);
  content: "";
  position: absolute;
}

.false::before {
  background-color: white;
  width: 5px;
  height: 30px;
  top: 15%;
  left: 45%;
  border-radius: 2.5px;
  transform: rotate(45deg);
  content: "";
  position: absolute;
}

.false::after {
  background-color: white;
  width: 5px;
  height: 30px;
  top: 15%;
  left: 45%;
  border-radius: 2.5px;
  transform: rotate(-45deg);
  content: "";
  position: absolute;
}

.false {
  margin: 10px 30px 10px 0px;
  width: 40px;
  background-color: var(--red);
  height: 40px;
  border-radius: 20px;
  position: relative;
}

.line {
  border: 1px solid white;
  height: 40px;
}

.line-purple {
  border: 1px solid var(--purple);
  height: 90%;
}

/* --------------------TwoBarChart-------------------- */
.TwoBarChart-Base {
  background-color: var(--dashboard-components-bg-color);
  border-radius: 5px;
  margin: var(--components-margin);
}

.TwoBarChart-title {
  box-sizing: border-box;
  margin-bottom: 20px;
}

.TwoBarChart-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
}

.TwoBarChart-content-left-barChart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 15px;
  font-size: 100%;
}

.TwoBarChart-content-left-barChart-column {
  display: flex;
  flex-direction: column;
}

.TwoBarChart-content-right-content {
  font-size: 95%;
}

.barChart-base {
  width: 400px;
  height: 10px;
  background-color: rgb(145, 142, 142);
  border-radius: 2px;
  margin: 6px 15px;
  position: relative;
}

/* --------------------GaugeChart-------------------- */
.GaugeChart-Base {
  background-color: var(--dashboard-components-bg-color);
  border-radius: 5px;
  margin: var(--components-margin);
}

.GaugeChart-title {
  box-sizing: border-box;
  margin-bottom: 20px;
}

.GaugeChartChart-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.GaugeChartChart-content {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
}

.GaugeChartChart-indicator {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 75px;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
}

.gauge-request-years {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
}

.gauge-request-years p {
  margin: 0;
  text-align: right;
}

#gauge-chart5 {
  width: 80%;
  margin: 0 auto;
}

#gauge-chart5 svg {
  width: 100%;
  height: auto;
  display: block;
}

.GaugeChartChart-legends {
  display: flex;
  flex-direction: column;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
  justify-content: center;
}

.legend-color {
  width: 14px;
  height: 10.5px;
  display: block;
  margin-right: 10px;
  background-color: currentColor;
}

.legend-color.needle {
  width: 0;
  height: 0;
  border-left: 7px solid #ffffff;
  border-right: 7px solid #ffffff;
  border-bottom: 10.5px solid #464a4f;
}

.legend-label {
  color: black;
  font-weight: 500;
}

/* --------------------openinglist--------------------- */
.openinglist-base {
  background-color: var(--dashboard-components-bg-color);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: var(--components-margin);
  overflow: hidden;
  display: flex;
  flex-direction: column;

}

.openinglist-header {
  background-color: var(--dashboard-components-bg-color);
  width: 100%;
  padding: 10px 0px;
  color: rgb(0, 0, 0);
  justify-content: center;
  border-radius: inherit;

}

.openinglist-body-base {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 80%;
  border-radius: 0px 0px 5px 5px;
}

.Selected {
  background-color: rgba(226, 218, 211, 0.582);
  width: 100%;
  color: rgba(0, 0, 0, 0.767);
  padding: 10px 0px;
}

.openinglist-body {
  background-color: var(--dashboard-components-bg-color);
  width: 100%;
  color: rgba(0, 0, 0, 0.767);
  padding: 10px 0px;
}

.openinglist-body:hover {
  background-color: rgba(107, 233, 255, 0.2);
}

.openinglist-left-text {
  display: inline-block;
  width: 75%;
  padding-left: 15px;
  overflow: hidden;
}

.openinglist-right-text {
  float: right;
  padding-right: 20px;
}

.openinglist-body-base::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.openinglist-body-base::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #72717171;
  border-radius: 5px;
}

.openinglist-body-base::-webkit-scrollbar-track {
  /* Background */
  background: var(--dashboard-components-bg-color);
  border-radius: 0px 0px 5px 0px;
}

.opening-list-footer {
  /* background-color: white;
  border-top: 1px solid #ddd; 
  text-align: center;
  padding: 5px;
  font-size: 14px; */
  padding: 5px 15px;
  background-color: #F0F0F0;
  color: rgb(70, 74, 79);
}

/* -----------------RemarkAndScore---------------------------- */

.add-comment-button {
  display: block !important;
}

.remarkAndScore {
  width: 100%;
  height: 100%;
  margin: var(--components-margin);
  display: flex;
  flex-direction: row;
  gap: 1.5%;
}

.remarkAndScore-Base {
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0px 0px;
  /* border: 1px solid #272727 !important; */
}

.remarkAndScore-title {
  font-size: 135%;
  height: 14%;
  background-color: var(--logo-bg-color);
  width: 100%;
  color: white;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0px 0px;
}

.remarkAndScore-title-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.remarkAndScore-content {
  font-size: 100%;
  width: 100%;
  height: 86%;
  background-color: var(--dashboard-components-bg-color);
  border-radius: 0px 5px 5px 5px;
  overflow: auto;
}

.remarkAndScore-content-text {
  padding: 15px;
}

.remarkAndScore-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.remarkAndScore-content::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #72717171;
  border-radius: 5px;
}

.remarkAndScore-content::-webkit-scrollbar-track {
  /* Background */
  background: var(--dashboard-components-bg-color);
  border-radius: 5px;
}

/* -----------------skillsMatched---------------------------- */
.skillsMatched-content-text {
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.skills-view-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

@media print {
  * {
    print-color-adjust: exact;
    /* font-size: 1rem; */
  }

  .add-comment-button {
    display: none !important;
  }

  .RHS-grid-container {
    display: grid;
    grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 5.5% 12.5%;
    grid-template-rows: 17.5% 10% 10% 15.5% 20% 21% !important;
    margin: 15px;
    height: calc(100vh - 20px);
    gap: 1%;
  }

  .analysis-dashboard-top-bar {
    display: block;
    font-weight: bold;
  }

  .analysis-dashboard-page,
  .RHS-grid-container-out {
    background-color: #fff;
    font-size: 10px !important;
  }

  .analysis-dashboard-page p {
    margin: 0;
  }

  .analysis-dashboard-page text {
    font-size: 18px;
  }

  .analysis-dashboard-lhs {
    display: none !important;
  }

  /* .RHS-grid-container {
    display: grid;
    grid-template-columns: 1.4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 10% 8% 8% 12% 20% 28.5%;
    margin: 15px;
    height: calc(100vh - 20px);
    gap: 1%;
  } */

  .RHS-grid-item {
    border: 1px solid #272727 !important;
  }

  .RHS-grid-item1 {
    grid-area: 1 / 1 / 2 / 3 !important;
  }

  .RHS-grid-item2 {
    grid-area: 1 / 3 / 2 / 5 !important;
  }

  .RHS-grid-item3 {
    grid-area: 5 / 1 / 7 / 9 !important;
    print-color-adjust: exact;
  }

  .RHS-grid-item4 {
    grid-area: 2 / 1 / 5 / 3 !important;
  }

  .RHS-grid-item5 {
    grid-area: 4 / 7 / 5 / 9 !important;
    print-color-adjust: exact;
  }

  .RHS-grid-item6 {
    grid-area: 4 / 7 / 5 / 5 !important;
    print-color-adjust: exact;
  }

  .one-box-square-Pink {
    width: 100%;
    background-color: var(--jardines-blue-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35%;
    border-radius: 0 !important;
    color: white;
    font-size: 10px;
    font-weight: bold;
  }

  .one-box-square-Purple {
    width: 100%;
    background-color: var(--jardines-blue-color);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 35%;
    border-radius: 0 !important;
    color: white;
    font-size: 10px;
    font-weight: bold;
  }

  .Selected {
    background-color: rgba(226, 218, 211, 0.582);
    width: 100%;
    color: rgba(0, 0, 0, 0.767);
    padding: 0 !important;
  }

  .openinglist-base {
    background-color: var(--dashboard-components-bg-color);
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
    margin: var(--components-margin);
    overflow: hidden;
  }

  .openinglist-body {
    background-color: var(--dashboard-components-bg-color);
    width: 100%;
    color: rgba(0, 0, 0, 0.767);
    padding: 0 !important;
  }

  .RHS-grid-item9 {
    grid-area: 2 / 3 / 5 / 5 !important;
    border: 0 !important;
  }

  .skillsMatched-content-text {
    padding: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 0px !important;
  }

  .printable-skills-matched {
    display: grid;
    grid-area: 5 / 3 / 6 / 9 !important;
  }

  .printable-view-box-inside-content-title {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 15px;
  }

  .printable-view-item {
    font-size: 10px;
    font-weight: none;
  }

  .RHS-grid-item10 {
    grid-area: 1 / 5 / 4 / 9 !important;
    overflow: hidden;
  }

  .RHS-grid-item11 {
    grid-row-start: 7;
    grid-column-start: 1;
    overflow: visible;

  }

  .RadarChart-Base {
    background-color: var(--dashboard-components-bg-color);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin: var(--components-margin);
    overflow: hidden !important;
  }

  .recharts-wrapper {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0px;

    svg {
      .recharts-surface {
        .recharts-layer.recharts-radar-chart {
          /* Adjust size of radar chart for print */
          width: 100% !important;
          height: 100% !important;
        }

        .recharts-layer.recharts-polar-angle-axis {
          .recharts-cartesian-axis-tick {
            font-size: 16px !important;
            /* Adjust font size for angle axis ticks */
          }
        }

        .recharts-layer.recharts-polar-radius-axis {
          .recharts-cartesian-axis-tick {
            font-size: 16px !important;
            /* Adjust font size for radius axis ticks */
          }
        }
      }
    }
  }

  .GaugeChartChart-body {
    width: 100% !important;
    height: auto !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px !important;
  }

  .GaugeChartChart-content svg {
    width: 100% !important;
    height: auto !important;
    display: block;
  }

  .GaugeChartChart-content {
    box-sizing: border-box;
  }

  .gauge-chart5 {
    width: 100% !important;
    height: auto !important;
    display: flex;
    justify-content: center;
  }

  .GaugeChartChart-indicator {
    display: grid;
    width: 100% !important;
    grid-template-columns: 1fr 1fr;
    column-gap: 75px;
    position: absolute;
    bottom: -60px;
    /* top: 20px; */
    left: 0;
    right: 0;
  }

  .remarkAndScore {
    display: flex !important;
    flex-direction: column !important;
    height: fit-content !important;
    border: 0 !important;
  }

  .remarkAndScore-Base {
    width: 100%;
    height: 100%;
    /* border-radius: 5px 5px 0px 0px; */
    border-radius: 0 !important;
    border: 1px solid #272727 !important;
  }

  .remarkAndScore-title {
    font-size: 135%;
    height: 14%;
    background-color: var(--logo-bg-color);
    width: 100%;
    color: white;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0 !important;
    /* border-radius: 5px 5px 0px 0px; */
  }


  .remarkAndScore-title-text {
    height: 35%;
    color: white;
    font-size: 12px;
    font-weight: bold;
  }

  .remarkAndScore:first-child {
    overflow: visible !important;
  }

  .remarkAndScore:first-child .remarkAndScore-content {
    overflow: visible !important;
  }

  .remarkAndScore:last-child {
    overflow: visible !important;
  }

  .remarkAndScore-content {
    break-inside: auto;
  }

  .PieChart-bottle>b>u {
    display: none;
  }

  .PieChart-content-pie {
    width: 12vh;
    height: 12vh;
    border-radius: 50%;
    border: 1px solid #000;
    display: flex;
    position: relative;
    print-color-adjust: exact;
  }

  .PieChart-content-pie-inside_text {
    font-size: 20px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
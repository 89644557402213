/* General styles */
* {
  font-family: "Segoe UI", sans-serif;
  box-sizing: border-box;
}

body {
  background: #fefefe;
}

.outlet-wrapper {
  position: relative;
  height: 100%;
}

/* Global styles */

:root {
  /* jardines Brand Gradient Colors */
  --jardines-10: #d8dce9;
  --jardines-20: #c1c7da;
  --jardines-30: #a9aebc;
  --jardines-40: #9397a0;
  --jardines-50: #7b7f84;
  --jardines-60: #666970;
  --jardines-70: #50535a;
  --jardines-80: #212B59;
  --jardines-90: #1E2D4E;
  --jardines-100: #1b2546;
  --jardines-110: #11182d;
  --jardines-120: #0e1425;
  --jardines-130: #0b101c;
  --jardines-140: #070b14;
  --jardines-150: #04070b;
  --jardines-160: #010303;

  /* Primary Brand Colors */
  --color-primary-light: var(--jardines-40);
  --color-primary: var(--jardines-80);
  --color-primary-dark: var(--jardines-100);

  /* Secondary Brand Colors */
  --color-secondary-light: var(--jardines-20);
  --color-secondary: var(--jardines-60);
  --color-secondary-dark: var(--jardines-90);

  /* Additional Brand Colors */
  --dashboard-bg-color: #E2DAD3;
  --dashboard-components-bg-color: #FFFFFF;
  --deeppurple: #4b2b69;
  --orange: #ca8300;
  --green: #008656;
  --red: #be0013;
  --pink: #ff126d;
  --deeppurple: #a0328e;
  --logo-bg-color: var(--jardines-100);
  --jardines-blue-color: var(--jardines-100);

  /* Link Colors */
  --color-link: #0078d4;
  --tag-bg-color: #9ABFDC;
  --tag-color: #002C4E;

  /* Pie Chart Sizes */
  --pie-size: 180px;
  --pie-intersize: 120px;

  /* Grayscale Colors */
  --color-gray-100: #f7f7f7;
  --color-gray-200: #e1e1e1;
  --color-gray-300: #cfcfcf;
  --color-gray-400: #b1b1b1;
  --color-gray-500: #9e9e9e;
  --color-gray-600: #7e7e7e;
  --color-gray-700: #626262;
  --color-gray-800: #515151;
  --color-gray-900: #3b3b3b;
  --color-gray-1000: #222222;

  /* Feedback Colors */
  --color-success: #5DC983;
  --color-warning: #FFC107;
  --color-danger: #FF5252;

  /* Border Radius */
  --border-radius-none: 0;
  --border-radius-sm: 2px;
  --border-radius-md: 4px;
  --border-radius-lg: 6px;
  --border-radius-xl: 8px;

  /* Spacing */
  --spacing-xxs: 2px;
  --spacing-xs: 4px;
  --spacing-snudge: 6px;
  --spacing-sm: 8px;
  --spacing-mnudge: 10px;
  --spacing-md: 12px;
  --spacing-lg: 16px;
  --spacing-xl: 20px;
  --spacing-xxl: 24px;
  --spacing-xxxl: 32px;

  /* Box Shadows */
  --box-shadow-sm: 0 0 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
  --box-shadow-md: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
  --box-shadow-lg: 0 0 2px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.14);
  --box-shadow-xl: 0 0 2px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.14);
  --box-shadow-xxl: 0 0 8px rgba(0, 0, 0, 0.12), 0 14px 28px rgba(0, 0, 0, 0.14);
  --box-shadow-xxxl: 0 0 8px rgba(0, 0, 0, 0.12), 0 32px 64px rgba(0, 0, 0, 0.14);
  --box-shadow-sm-brand: 0 0 2px rgba(0, 0, 0, 0.30), 0 1px 2px rgba(0, 0, 0, 0.25);
  --box-shadow-md-brand: 0 0 2px rgba(0, 0, 0, 0.30), 0 2px 4px rgba(0, 0, 0, 0.25);
  --box-shadow-lg-brand: 0 0 2px rgba(0, 0, 0, 0.30), 0 4px 8px rgba(0, 0, 0, 0.25);
  --box-shadow-xl-brand: 0 0 2px rgba(0, 0, 0, 0.30), 0 8px 16px rgba(0, 0, 0, 0.25);
  --box-shadow-xxl-brand: 0 0 8px rgba(0, 0, 0, 0.30), 0 14px 28px rgba(0, 0, 0, 0.25);
  --box-shadow-xxxl-brand: 0 0 8px rgba(0, 0, 0, 0.30), 0 32px 64px rgba(0, 0, 0, 0.25);
}

.top-navbar {
  border-bottom: 1px solid rgb(225, 223, 221);
  z-index: 1000;
  width: 100%;
  height: 82px;
  top: 0;
  left: 0;
  position: sticky;
  padding: 0.8rem 2rem;
  background-color: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
}

.top-navbar .nav-link-items a {
  color: #282c34;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.top-navbar .nav-link-items a:hover,
.top-navbar .user-link:hover {
  color: #0078d4;
  text-decoration: none;
}

.top-navbar .nav-link-items a.active,
.top-navbar .user-link {
  color: #0078d4;
  font-weight: 600;
}

.top-navbar .nav-link-items {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2rem;
}

.logo-container {
  height: 100%;
}

.logo-container img {
  height: 55px;
  width: auto;
}

.persona {
  cursor: pointer;
}

.user-link {
  color: #282c34;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-link i {
  font-size: 20px;
}

.persona .ms-Persona-details {
  display: none;
}

.ms-Dialog-main {
  border-radius: 8px;
}

.modal-container {
  overflow: auto;
}

.modal-scroll-content {
  max-height: 100%;
}

.ms-Button-flexContainer {
  gap: 8px;
  font-weight: 500;
  align-items: center;
  display: flex;
}

.ms-Button-flexContainer a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
}

.stack-btn {
  padding: 0 6px;
}

.ms-TextField--multiline {
  width: 100%;
}

.job-from .ms-TextField {
  width: 100%;
}

.ms-Icon {
  font-size: large;
}

.label-126 {
  font-weight: 500;
}

.skills-item {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.skills-item-selected {
  display: block;
}

.upload-container {
  height: 20vh;
}

.upload-container input {
  width: 100%;
}
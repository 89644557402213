/* JobOpening View */

/* JobOpenings.css */

/* General Styles */
* {
  font-family: "Segoe UI", sans-serif;
  box-sizing: border-box;
}

body {
  background: #fefefe;
}

.outlet-wrapper {
  position: relative;
  height: 100%;
}

/* Navbar */


/* Candidates */
.ms-TextField-wrapper label::after {
  color: #000;
}

.skills-item {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.skills-item-selected {
  display: block;
}

/* Job Opening */
.job-stack button {
  /* border-radius: 8px !important; */
}

.job-stack button i {
  color: #000;
  font-size: 18px;
}

.dropzone {
  height: 80px;
  border: 1px dashed;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Job Table */


.job-table {
  min-width: 600px;
  width: 100%;
  border-collapse: collapse;
}

/* .job-table thead tr {
  height: 50px;
} */

.job-table td,
.job-table th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  height: 51px;
}

.job-title:hover {
  cursor: pointer;
  color: #0078d4;
  text-decoration: underline;
}

.matched-candidate:hover {
  cursor: pointer;
  color: #0078d4;
  text-decoration: underline;
}

.reviewed-cell:hover,
.matched-candidate-cell:hover {
  background-color: #fafafa;
}

.job-table .selected-row {
  background-color: #e0e0e0;
}

.under-review {
  background-color: #ece5fd;
  color: #0078d4;
}

.candidates-details-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* JobTable */

.employee-table-container {
  padding: 0 20px;
  box-sizing: border-box;
}

.job-openings-header {
  display: flex;
  justify-content: space-between;
}

.job-table-toolbar {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  /* box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.10); */
  font-size: 16px;
  line-height: 20px;
  background-color: #FFFFFF;
  color: #6074EF;
  width: 100%;
}

.filter-input {
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;
  padding: 10px 0
}

.filter-input-container {
  display: flex;
  row-gap: 12px;
  justify-content: space-between;
  padding: 10px 12px;
  flex-wrap: wrap;
  height: 104px;
  overflow-y: auto;

}

.job-table {
  width: 100%;
  border-collapse: collapse;
}

.table-title-main {
  cursor: pointer;
}

.table-title {
  cursor: pointer;
}

.matched-candidate {
  cursor: pointer;
  font-size: 14px;
}

.under-review-cell {
  background-color: #f0f0f0;
}

.selected-row {
  background-color: #d0d0d0;
}

.employee-table-modal {
  max-height: 90vh;
  overflow-y: auto;
}

.table-na {
  display: flex;
  width: 100%;
  /* justify-content: center; */
  padding-left: 40px;
  font-style: italic
}


.job-openings-pagination-container {
  flex-shrink: 0;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

@media screen and (max-width: 1320px) {
  /* .job-openings-header {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  } */

  .job-table-toolbar {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .filter-input {
    display: flex;
    column-gap: 12px;
  }

  
}
/* Skeleton */

/* General container styles */
.skeleton-general-container {
  width: 100%;
  height: calc(100vh - 82px);
  overflow: auto;
  background-color: #f5f5f5;
}

/* Grid layout for main content */
.skeleton-main-content-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 12px 24px;
  gap: 12px;
  height: 100%;
}

/* Flex container for sections */
.skeleton-section-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  overflow: hidden;
  padding: 5px;
}

/* Top bar styles */
.skeleton-top-bar {
  display: flex;
  justify-content: space-between;
}

/* Personal details and skills status styles */
.skeleton-details-container,
.skeleton-skills-status-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
  gap: 20px;
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
}

/* Skeleton items container */
.skeleton-items-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 148px;
  padding: 40px 24px 0;
}

/* Side section styles */
.skeleton-side-section {
  display: flex;
  gap: 12px;
  padding: 5px;
}

/* Divider styles */
.skeleton-divider-vertical {
  height: 100%;
}

/* Side content container */
.skeleton-side-content-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  row-gap: 5px;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
  width: 100%;
  overflow: auto;
}

/* Candidates View */

/* General layout styles */
.flex-center {
  display: flex;
  align-items: center;
  gap: 5px;
}

.search-panel {
  width: 450px;
  height: calc(100vh - 82px);
  overflow: auto;
  border-right: 1px solid rgb(225, 223, 221);
}

.search-panel-content {
  padding: 24px;
}

.main-content {
  width: 100%;
  height: calc(100vh - 82px);
  overflow: auto;
}

.search-icon-container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: #242424;
}



.search-icon-text {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.result-retrieving {
  /* padding: 0 24px; */
}

.result-count {
  display: flex;
  justify-content: flex-end;
}

/* Item cell and content styles */
.item-cell {
  min-height: 54px;
  padding: 10px;
  box-sizing: "border-box";
  border-bottom: "1px solid";
  display: "flex";
}

.item-content-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.candidate-name-clickable {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 0;
}

.bold-text {
  color: #7676d2;
  font-weight: bold;
}

.description-text {
  flex: 1;
  padding: 8px;
}

.no-record {
  font-style: italic;
}

.read-more {
  color: #0078d4;
  cursor: pointer;
}

.item-category {
  font-weight: bold;
  color: "#858585";
}

/* Button styles */
.primary-button {
  background-color: rgb(91, 95, 199);
  color: white;
  border-color: rgb(91, 95, 199);
  border-radius: 4px;
}

/* Comment */
.candidates-comment-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  row-gap: 5px;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
  width: 100%;
  overflow: auto;
}

.comment-header {
  display: flex;
  justify-content: space-between;
}

/* .comment-divider {  
    flex-grow: 0 !important;
  }   */

.comment-input-container {
  display: flex;
  padding: 12px 0;
}

.avatar-container {
  width: 56px;
}

.comment-input {
  width: 100%;
}

.text-input-toolbar {
  display: flex;
  flex-grow: 1;
}

.comment-list {
  display: flex;
  flex-direction: column-reverse;
  row-gap: 12px;
}

.comment-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f5f5;
}

.comment-timestamp {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.action-buttons {
  display: flex;
}

.editable-comment-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.editable-textarea {
  width: -webkit-fill-available;
}

.edit-actions {
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
}

.card-preview {
  padding: 0px 12px 12px 56px;
}

.search-candidate-content {
  position: relative;
  padding: 24px 48px;
  background-color: #FAFAFA;
  height: calc(100vh - 82px);
  display: flex;
  column-gap: 20px;
}

.search-panel-filter-mobile {
  display: none;
}

.search-panel-filter {
  min-width: 300px;
  flex-shrink: 0;
}

.search-candidate-result {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  background-color: #FFFFFF;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .search-candidate-content {
    position: relative;
    padding: 24px 48px;
    background-color: #FAFAFA;
    height: calc(100vh - 82px);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .search-panel-filter-mobile {
    display: block;
    cursor: pointer;
  }

  .search-panel-filter {
    display: none;
  }
}
.top-matches-page-container {
    position: relative;
    padding: 24px 48px;
    background-color: #f5f5f5;
    height: calc(100vh - 82px);
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.top-matches-component-layout {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.top-matches-filter {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    column-gap: 10px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    font-size: 16px;
    line-height: 20px;
    background-color: #ffffff;
    color: #6074ef;
}

.top-matches-filter-header {
    width: 100%;
    padding: 12px;
}

.top-matches-filter-container {
    display: flex;
    row-gap: 12px;
    justify-content: space-between;
    padding: 10px 12px;
    flex-wrap: wrap;
    height: 104px;
    overflow-y: auto;
}

.top-matches-new-application-toast {
    display: flex;
    flex-shrink: 0;
    column-gap: 10px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    line-height: 20px;
    background-color: #ffffff;
    color: var(--color-link);
}

.hidden {
    display: none;
}

.toast-contents {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.top-matches-pagination-container {
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
}

.fui-CalendarDayGrid__dayButton>.fy35frb {
    background-color: var(--tag-bg-color) !important;
}
/* Home View */  

.image-container {  
  height: 40vh;  
  overflow: hidden;  
}  
  
.image-wrapper {  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  width: 100%;  
  height: 100%;  
}  
  
.image-style {  
  width: 100%;  
  height: auto;  
  object-fit: contain;  
}  
  
.landing-page-cards-container {  
  max-width: 1320px;  
  margin: auto;  
  display: flex;  
  justify-content: space-between;  
  margin-top: -3rem;  
}  
  
.image-wrapper-index {  
  width: 100%;  
  height: 100%;  
}  
  
.image-style-index {  
  width: 100%;  
  height: 100%;  
  object-fit: cover;  
}  
  
.card-header-style {  
  min-height: 60px;  
}  
  
.card-footer {  
  display: flex;  
  flex-direction: column;  
  row-gap: 12px;  
  width: 100%;  
}  
  
/* Media Queries */  
@media screen and (max-width: 1320px) {  
  .landing-page-cards-container {  
    display: grid !important;  
    grid-template-columns: 1fr 1fr;  
    padding: 20px;  
    grid-row-gap: 20px;  
    grid-column-gap: 20px;  
  }  
}  
  
@media screen and (max-width: 768px) {  
  .landing-page-cards-container {  
    display: grid !important;  
    grid-template-columns: 1fr;  
    padding: 20px;  
    grid-row-gap: 20px;  
  }  
}  

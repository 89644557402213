/* Detail Page */

.candidates-detail-row {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 10px;
    margin-bottom: 16px;
  }
  
  .candidates-detail-title {
    font-weight: bold;
  }
  
  .candidates-detail-description {
    display: flex;
    flex-direction: column;
  }

  .candidates-detail-description > div > p {
    margin-block-start: 0.2rem;
  }
  